module.exports = {
    title: 'Api管理系統',
    /** 
     * @type {boolean}
     * @description
     */
    tagsView: true,
    /**
     * @type {boolean}
     * @description
     */
    fixedHeader: false,
    /**
     * @type {boolean}
     * @description
     */
    sidebarLogo: false
}