import Cookies from 'js-cookie'

const TokenKey = 'apiToken'


export function getToken() {
  //console.log("get token");
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  //console.log("setToken");
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  console.log("remove apiToken");
  return Cookies.remove(TokenKey)
}
