import axios from 'axios';
import store from '@/store';
import { MessageBox, Message } from 'element-ui';
import router from '../router';
import { getToken } from '@/api/auth';

// console.log(process.env);
const service = axios.create({
  //baseURL: process.env.VUE_APP_BASE_API,
  timeout: 120000,
  baseURL: "https://35.194.230.94/",
  // headers: {
  //   "Content-Type":"multipart/form-data",
  //   "accept": "multipart/form-data"
  // }
  // timeout: 60000,
});

service.interceptors.request.use(
  (config) => {    
    //console.log("config",config);
    let token = getToken();
    if( config.method=="get" ) 
    {
      //console.log("get method");
      token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzY29wZXMiOlsic3lzdGVtIl0sInN1YiI6InN5c3RlbSIsImV4cCI6NDgxODYzNDMzOH0.iQYNjK62wuKsf5Baq5SQm4vBsyYPrhvGC_j2oReEszs";
    }
      
    
    // 看起來, 只要判斷config.data!=FormData 
    // 將 config.data 反序列化後, 讀取isUser 
    // 若無isSYS 或 isSYS=false 則用認證..
    // 若有isSYS=true, 則用永久token...
    
      if(token)
        config.headers['Authorization'] = `Bearer ${token}`;


    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {        
    const res = response.data;
    return res;
  },
  (error) => {
    console.log(error);
    const { response } = error;
    store.dispatch('loadingHandler', false);
    if (response) {
      errorHandle(response.status, response.data.error, response);
      return Promise.reject(error);
    } else {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000,
      });
      return Promise.reject(error);
    }
  }
);

/* 錯誤捕捉 */
const errorHandle = (status, msg, response) => {
  switch (status) {
    case 400:
      break;

    case 401:
      tokenExpire();
      break;

    case 403:
      break;

    case 404:
      router.replace({
        name: '404',
      });
      break;

    case 500:
      break;

    default:
      break;
  }
};

const tokenExpire = () => {
  Message({
    message: '登入逾時，請重新登入！',
    type: 'error',
    duration: 3000,
  });
  /* 登出api */
  store.dispatch('Logout');
  router.replace({
    name: 'login',
  });
};

export default service;
