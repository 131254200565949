import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/Login.vue"),
    meta: {
      hideMenu: true,
    },
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/404.vue"),
    meta: {
      hideMenu: true,
    },
  },
  {
    path: "/account-management",
    name: "account-management",
    component: () => import("../views/accMgt.vue"),
    meta: {
      hideMenu: false,
    },
  },

  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: {
      hideMenu: false,
    },
  },

  /*管理報表__系統登入記錄*/
  {
    path: "/login-record",
    name: "login-record",
    component: () => import("../views/sysLoginLog.vue"),
    meta: {
      title: "系統登入記錄",
      hideMenu: false,
    },
  },
  /*管理報表__系統操作記錄*/
  {
    path: "/operate-record",
    name: "operate-record",
    component: () => import("../views/sysOperateLog.vue"),
    meta: {
      title: "系統操作記錄",
      hideMenu: false,
    },
  },

  
  /*管理報表__API使用狀況統計表*/
  {
    path: "/api-summary",
    name: "api-summary",
    component: () => import("../views/apiSummary.vue"),
    meta: {
      title: "API使用狀況統計表",
      hideMenu: false,
    },
  },

  /*授權與認證*/
  {
    path: "/auth-management",
    name: "auth-management",
    component: () => import("../views/authManage.vue"),
    meta: {
      hideMenu: false,
    },
  },
];

const router = new VueRouter({
  routes,
});


// 讓每一換頁, 一律捲到最上面...
router.afterEach((to, from) => {
  const main = document.querySelector('.el-main')
  main.scrollTo(0, 0)
})

export default router;
