<template>
  <div id="app">
    <link href="https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@300;500;700&display=swap" rel="stylesheet">
    <Loading />
    <div id="nav">
      <el-aside v-if="!$route.meta.hideMenu" class="mainLayoutAside"
        :class="{ isCollapse: isCollapse }"
      >
        <div class="mainLayoutAside__title"
          :class="{ mainLayoutAside__shrink: isCollapse }"
          :style="{ 'flex-direction: column': !isCollapse }"
        >
          <img :src="logoImg" alt="logo" :width="getLogoWidth">
          <i class="el-icon-s-unfold"
            :class="{ goRound: !isCollapse }"
            @click="isCollapse = !isCollapse"
          ></i>
        </div>
        <div class="userNameWrap" v-show="!isCollapse"><strong>{{getUserProfile.UserName?getUserProfile.UserName:'使用者'}} 您好</strong></div>
        <el-menu
          :default-active="$route.name"
          :show-timeout="200"
          :collapse-transition="false"
          :unique-opened="uniqueOp"
          :collapse="isCollapse"
          background-color="#191972"
          text-color="#fff"
          active-text-color="#ffd04b"
          :router="false"
        >
          <Sidebar v-for="menu in routes" :key="menu.id"
            :isCollapse="isCollapse"
            :item="menu"
            @doRoute="doRoute"
          />
        </el-menu>
      </el-aside>

      <el-main :class="{ withUp: isCollapse, noBar: $route.meta.hideMenu }">
        <div class="viewHeight">
          <router-view></router-view>
        </div>
      </el-main>
    </div>
  </div>
</template>

<script>
import { mapGetters} from "vuex";
import Loading from "./components/Loading.vue";
import Sidebar from "./components/Sidebar.vue";

export default {
  components: { Loading, Sidebar },
  data() {
    return {
      userName:"",
      // userInfo: null,
      isCollapse: true,
      uniqueOp: true,
      transitionName: "slide-left",
      logoImg:require('../src/assets/logo.png')
    };
  },
  methods: {
    doRoute(val) {
      this.$router.push({ name: val });
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
    getUserProfile: {
      get() {
        // let roleArr = [
        //   { id: "Teacher", name: this.$t("role.teacher") },
        //   { id: "Student", name: this.$t("role.student") },
        //   { id: "PartTimeWorker", name: this.$t("role.partTimeWorker") },
        //   { id: "TeacherAssistant", name: this.$t("role.teacherAssistant") },
        // ];
        // this.$store.state.userInfo.roleName = roleArr.filter(
        //   (i) => i.id === this.$store.state.userInfo?.role
        // )[0]?.name;
        return this.$store.state.userInfo || {};
      },
    },
    getLogoWidth() {
      return this.isCollapse ? "50px" : "80px";
    },
    routes() {
      return this.$store.state.menuList;
    },
  },
  mounted() {
    // user
    // this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    // this.userName = this.$store.state.userName || window.localStorage.getItem("userName") || ""
    // console.log('appUserName:',this.$store.state.userName);
    // console.log('ueserInfo',this.$store.state.userInfo);
  },
};
</script>

<style lang="scss">
#nav {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  .userNameWrap{
    padding-left: 20px;
  }
  .mainLayoutAside {
    position: fixed;
    z-index: 2;
    height: 100vh;
    background: #191972;
    color: #fff;
    transition: 0.5s;
    width: 250px !important;

    &.isCollapse {
      width: auto !important;
    }

    &__title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      strong {
        font-size: 24px;
        color: white;
      }

      i {
        padding: 0 8px;
        font-size: 24px;
        margin-left: auto;
        transition: 0.5s;
        cursor: pointer;

        &.goRound {
          transform: rotate(180deg);
        }
      }
    }

    &__shrink {
      flex-direction: column;

      i {
        font-size: 20px;
        margin-left: 0;
      }
    }
  }

  .el-main {
    padding: 0;
    height: 100%;
    margin-left: 250px;

    &.withUp {
      margin-left: 50px;
    }

    &.noBar {
      margin-left: 0 !important;
    }
  }
}
.el-menu {
  &--collapse {
    width: auto !important;
  }
}
</style>
