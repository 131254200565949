import router from "./router";
import store from "./store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import {
    getToken
} from "./api/auth";
import getPageTitle from "./api/get-page-title";

NProgress.configure({
    showSpinner: false,
});

router.beforeEach(async (to, from, next) => {
    // hasToken 要每次進來都抓不然不會改變

    const hasToken = getToken();
    NProgress.start();
    document.title = getPageTitle(to.meta.title);
    store.dispatch("SaveHistory",from);
    // 如果有登入進來拿資料然後去想去的地方
    if (to.name) {
        if (to.name !== 'login') {
            if (!hasToken) {
                next('/')
            } else {
               
                /* 獲取登入者資訊 */
                await store.dispatch("GetInfo").then(() => {
                    /* 設置sidebar */
                    //console.log("設置sidebar");
                    store.dispatch("GetMenu");
                    next()
                }).catch(() => {
                    console.log("GetInfo Error!!!");
                    next('/')
                });
                // next()
            }
        }
        next()
    } else {
        next()
    }
});
router.afterEach(() => {
    NProgress.done();
})