import Vue from "vue";
import Vuex from "vuex";
import { getToken, removeToken, setToken } from "../api/auth";
import API from "../api/api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    token: getToken(),
    userInfo: {},
    menuList: null,
    routerFrom:"",
  },
  mutations: {
    SETLOADING(state, payload) {
      state.loading = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_USERINFO(state, payload) {
      state.userInfo = payload;
    },
    SET_MENU(state, payload) {
      state.menuList = payload;
    },
    SET_ROUTERFROM(state, payload){
      state.routerFrom = payload;
    }
  },
  actions: {
    loadingHandler(state, payload) {
      state.commit("SETLOADING", payload);
    },
    Login({ commit }, userInfo) {
      //console.log(userInfo);
      return new Promise((resolve, reject) => {

        //產生formData 物件...
        var formData = new FormData();
        formData.append('username', userInfo.username);
        formData.append('password', userInfo.password);

        API.apimgrs.login(formData).then(res=>{
          //console.log("res",res);
          if (!!res) {
            //console.log("res success SETTOKEN");
            setToken(res.access_token);
            commit("SET_TOKEN", res.access_token);
            window.localStorage.setItem("userID", res.UserID);
            window.localStorage.setItem("userRole", res.Role);
            resolve("success");
          } else {
            resolve(res);
          }
        })
        .catch((err) => {
          //console.log(err.response);
          reject(err.response);
        });
      });
    },
    Logout({ commit }) {
      removeToken();
      commit("SET_TOKEN", "");
    },
    GetInfo({ commit }) {
      // console.log("GetInfo打api");
      return new Promise((resolve, reject) => {
        
        const req = { UserID: window.localStorage.getItem("userID"),Page_Size:"10", Page_Num:"1" };
        //console.log("post",req);
        API.apimgrs.getInfo(req)
          .then((res) => {     
            //console.log("res.data[0]",res.data[0]);       
            commit("SET_USERINFO", res.data[0]);
            resolve();
          })
          .catch(() => {
            console.log("GetInfo Error!");
            reject();
          });
      });
    },
    GetMenu({ commit }) {      
      API.apimgrs.getMenu().then((res) => {
        //console.log("Menu",res);
        const { menu } = res;
        //console.log("menu",menu);
        let mainMenu = menu.filter((main) => main.Pid === 0);
        //console.log("mainMenu",mainMenu);
        const childrenMenu = menu.filter((children) => children.Pid !== 0);
        const menuList = mainMenu.map((p) => {
          p.children = childrenMenu.filter((c) => c.Pid == p.Id);
          return p;
        });
        //console.log(menuList);
        commit("SET_MENU", menuList);
      }).catch(err=>{
        console.log("err GetMemu",err);
      });
    },
    SaveHistory({ commit },routerFrom) {
      commit("SET_ROUTERFROM", routerFrom.name);
    }
  },
  
});

export const getters = {
  userInfo: state => state.userInfo,
}
