import request from "../request";
import axios from 'axios';

const api = {
  // 登入
  // login(username, password,sourceIp ){
  //   return request({
  //     url: "/bid/amp/token/",
  //     method: "post",
  //     data: {
  //       username: username,
  //       password: password
  //     },
  //   });
  // },
  login(data ){
    return request({
      url: "/bid/amp/token/",
      method: "post",
      data,
    });
  },

  getAllUser(data) {
    return request({
      url: "/bid/amp/users/search_user/",
      method: "post",
      data,
    });
  },

  getMenu(){    
    return request({
      url: "/bid/amp/system/menu/",
      method: "get"
    });
  },

  getDeptName(strDep) {

    let strUrl = "/bid/amp/system/company_info/" + strDep
    //https://35.194.230.94/bid/amp/system/company_info/Minds
    return request({
      url: strUrl,
      method: "get"
    });
  },

  // 授權管理Api

  addApi(data) {
    return request({
      url: "/bid/amp/manage/add_api/",
      method: "post",
      data,
    });
  },



  deleteApi(data){
    return request({
      url: "/bid/amp/manage/delete_api/",
      method: "post",
      data,
    });
  },
  updateApi(data){
    return request({
      url: "/bid/amp/manage/add_api/",
      method: "post",
      data,
    });
  },

  getApi(data){
    return request({
      url: "/bid/amp/manage/search_api/",
      method: "post",
      data,
    });
  },

  // 系統操作紀錄
  actionRecord(data){
    return request({
      url: "/bid/amp/audit/search_audit/",
      method: "post",
      data,
    });
  },  

  // 系統api統計
  apiStatic(data){
    return request({
      url: "/bid/amp/audit/api_statistics/",
      method: "post",
      data,
    });
  },  

  // 系統登入紀錄
  loginRecord(data){
    return request({
      url: "/bid/amp/audit/search_token/",
      method: "post",
      data,
    });
  },  

  // 使用者

  getInfo(data){
    return request({
      url: "/bid/amp/users/search_user/",
      method: "post",
      data,
    });
  },

  addUser(data) {
    return request({
      url: "/bid/amp/users/add_user/",
      method: "post",
      data,
    });
  },
  deleteUser(data){
    return request({
      url: "/bid/amp/users/delete_user/",
      method: "post",
      data,
    });
  },
  updateUser(data){
    return request({
      url: "/bid/amp/users/update_user/",
      method: "post",
      data,
    });
  },  
  updateUserPassword(data) {
    return request({
      url: "/bid/amp/users/update_user/",
      method: "post",
      data,
    });
  },
  updatePassword(data) {
    return request({
      url: "/bid/amp/users/update_password/",
      method: "post",
      data,
    });
  },  
  getAllUser(data) {
    return request({
      url: "/bid/amp/users/search_user/",
      method: "post",
      data,
    });
  },
};
  
export default api;